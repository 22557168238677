import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {LocaleConfig, NgxDaterangepickerMd} from "ngx-daterangepicker-material";
import dayjs from "dayjs/esm";
import {
  DaterangepickerComponent, DateRanges,
  EndDate,
  StartDate,
  TimePeriod
} from "ngx-daterangepicker-material/daterangepicker.component";
import {DashboardFilterDataService} from "../../../services/dashboard-filter-data/dashboard-filter-data.service";
import {FullCalendarDateFilterService} from "./full-calendar-date-filter.service";

@Component({
  selector: 'app-full-calendar-date-filter',
  standalone: true,
    imports: [
        FormsModule,
        NgxDaterangepickerMd
    ],
  templateUrl: './full-calendar-date-filter.component.html',
  styleUrl: './full-calendar-date-filter.component.scss'
})
export class FullCalendarDateFilterComponent {
  options = {
    autoApply: false,
    alwaysShowCalendars: false,
    showCancel: true,
    showClearButton: false,
    linkedCalendars: true,
    singleDatePicker: false,
    showWeekNumbers: false,
    showISOWeekNumbers: false,
    customRangeDirection: true,
    lockStartDate: false,
    closeOnAutoApply: true,
    timePicker: true
  };
  minDate: dayjs.Dayjs = dayjs().subtract(90, 'days');
  maxDate: dayjs.Dayjs = dayjs().add(3, 'month');
  ranges: DateRanges = {
    ['Aujourd\'hui']: [dayjs(), dayjs()],
    ['Hier']: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
    ['Cette semaine (lundi dernier)']: [dayjs().subtract(6, 'days'), dayjs()],
    ['7 derniers jours']: [dayjs().subtract(6, 'days'), dayjs()],
    ['Ce mois-ci']: [dayjs().startOf('month'), dayjs().endOf('month')],
    ['30 derniers jours']: [dayjs().subtract(29, 'days'), dayjs()],
    ['90 derniers jours']: [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    ['Dernière mise à jour']: [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  };
  picker: DaterangepickerComponent;
  locale: LocaleConfig = {
    format: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    displayFormat: 'DD/MM/YYYY HH:mm',
    separator: ' au ',
    cancelLabel: 'Annuler',
    applyLabel: 'Filtrer'
  };
  selected: any = { start: null, end: null };
  isLastDate: boolean = true
  @Input() filterLabel: boolean = true;
  @Output() dateChange = new EventEmitter<{dateParam: any, isLastDate: boolean}>();

  constructor(
    private dashboardFilterService: DashboardFilterDataService,
    private fullCalendarDateFilterService: FullCalendarDateFilterService,
  ) {
  }


  eventClicked(e: StartDate | EndDate): void {
    // eslint-disable-next-line no-console
    //console.log({ ['eventClicked()']: e });
  }

  eventCleared(): void {
    // eslint-disable-next-line no-console
    //console.log('datepicker cleared');
  }

  ngModelChange(e: Event): void {
    console.log('ngModelChange', e);
    if (this.selected.start !== null && this.selected.start !== "") {
      this.dateChange.emit({dateParam: this.selected, isLastDate: false});
      this.isLastDate = false
      // this.dashboardFilterService.filterData({ startDate: this.selected.start.format('DD/MM/YYYY'), endDate: this.selected.end.format('DD/MM/YYYY'), startHour: this.selected.start.format('HH:mm:ss'), endHour: this.selected.end.format('HH:mm:ss'), isLastDate: this.isLastDate })
    }
  }

  click(): void {
    console.log('click');
  }

  change(e: Event | TimePeriod | null): void {
    //console.log({ ['change()']: e });
    //this.filterStartDate = this.selected.start.format('DD/MM/YYYY HH:mm');
  }
}
