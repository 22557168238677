<section class="fullcalendar-date-filter">
  <div class="nav-date-filter d-flex align-items-center">
    @if(filterLabel){
      <span class="filter-label">Filtrer par date: </span>
    }
    <div class="filter-component">
      <input
        type="text"
        matInput
        ngxDaterangepickerMd
        [autoApply]="options.autoApply"
        [linkedCalendars]="options.linkedCalendars"
        [singleDatePicker]="options.singleDatePicker"
        [locale]="locale"
        [showDropdowns]="true"
        startKey="start"
        endKey="end"
        [(ngModel)]="selected"
        (click)="click()"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [ranges]="ranges"
        [showWeekNumbers]="options.showWeekNumbers"
        [showCancel]="options.showCancel"
        [showClearButton]="options.showClearButton"
        [showISOWeekNumbers]="options.showISOWeekNumbers"
        [customRangeDirection]="options.customRangeDirection"
        [lockStartDate]="options.lockStartDate"
        [closeOnAutoApply]="options.closeOnAutoApply"
        [alwaysShowCalendars]="true"
        firstMonthDayClass="first-day"
        lastMonthDayClass="last-day"
        emptyWeekRowClass="empty-week-row"
        emptyWeekColumnClass="empty-week-column"
        lastDayOfPreviousMonthClass="last-previous-day"
        firstDayOfNextMonthClass="first-next-day"
        [timePicker]="true"
        [timePicker24Hour]="true"
        name="daterange"
        (startDateChanged)="eventClicked($event)"
        (endDateChanged)="eventClicked($event)"
        (change)="change($event)"
        (ngModelChange)="ngModelChange($event)"
        (clearClicked)="eventCleared()"
      />
    </div>
  </div>
</section>
